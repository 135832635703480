.change-pas {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  &__button {
    margin-top: 1rem;
    width: 100%;
    height: 2.2rem;
    background: #079c20;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
  }
}