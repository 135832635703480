.spinner {
  // animation: rotate 2s linear infinite;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  
  // &__circle {
  //   stroke: hsl(210, 70, 75);
  //   stroke-linecap: round;
  //   animation: dash 1.5s ease-in-out infinite;
  // }

  &__logo {
    width: 110px;
    min-width: 110px;
    animation: colorChange 2.5s ease-in-out infinite;
    animation-direction: alternate
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }
}

@keyframes colorChange {
  100% {
    filter: hue-rotate(158deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}