.total {
  background: #f6f6f6;
  &__title {
    background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
  }
  &__td {
    width: 25%;
    text-align: right;
    &_type {
      &_left {
        text-align: left;
      }
    }
  }
  &__input-block {
    padding: .5rem;
    margin-top: .4rem;
    box-sizing: border-box;
    border-bottom: 1px solid #bbb;
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    background: #079c20;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
    &_isLoading {
      background: #11541c;
      cursor: not-allowed;
    }
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__result-header {
    background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-size: 0.9rem;
    color: #fff;
    font-weight: 400;
  }
  &__result {
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  &__row {
    height: 1.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-size: 0.8rem;
    font-weight: 400;
    background: #fff;
    border-bottom: 1px solid #ddd;
    &_type {
      &_bold {
        font-weight: bold;
      }
    }
  }
}
